import * as actionTypes from 'store/action-types';
import { Action, TSEBackupState } from 'shared/interface';
import { PER_PAGE } from 'shared/constants/constants';

const initialState: TSEBackupState = {
	files: [],
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0,
	},
	closeModal: false
};

const reducer = (state: TSEBackupState = initialState, action: Action): TSEBackupState => {
	switch (action.type) {
		case actionTypes.FETCH_TSE_BACKUP_HISTORY_INIT:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: 0,
				},
				files: []
			}
		case actionTypes.FETCH_TSE_BACKUP_HISTORY_SUCCESS:
			return {
				...state,
				pagination: action.payload.pagination,
				files: action.payload.items,
			}
		case 'RESET_MODAL':
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
};

export default reducer;
