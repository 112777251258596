import * as Yup from 'yup';
import { errorMessages } from 'shared/constants/messages';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';

const getSchema = (schemaConfig: any): any => {
	return Yup.object().shape(schemaConfig);
}

const getSendEmailValidationSchema = () => Yup.object().shape({
	to: Yup.string().email().min(2, errorMessages.minLength(localizationConstants.recipientEmail, 12))
		.required(errorMessages.required(localizationConstants.recipientEmail)).strict(true),
	cc: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email)),
	subject: Yup.string().required(errorMessages.required(localizationConstants.subject)).strict(true),
	content: Yup.string().required(errorMessages.required(localizationConstants.content)).strict(true),
});

const getAddLinceseFormValidationSchema = () => Yup.object().shape({
	expiryDate: Yup.date().when("expiryDateType", {
		is: ((value: string) => value === localizationConstants.custom),
		then: Yup.date().nullable().required(errorMessages.required(localizationConstants.expiryDate))
	}),
	[localizationConstants.activateAutoLicense]: Yup.array(),
	licenseVersion: Yup.string().required(errorMessages.select(localizationConstants.version)).strict(true),
	typeValue: Yup.number().when("expiryDateType", {
		is: ((value: string) => value === localizationConstants.year),
		then: Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.required(errorMessages.required(localizationConstants.value))
	}).when("expiryDateType", {
		is: ((value: string) => value === localizationConstants.month),
		then: Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.max(12, errorMessages.maxValue(localizationConstants.value, 12))
			.required(errorMessages.required(localizationConstants.value))
	}),
});

const getAddHardwareValidationSchema = () => Yup.object().shape({
	hardwareKey: Yup.string().required(errorMessages.required(localizationConstants.hardwareKey))
		.test('len', errorMessages.exactLength(localizationConstants.hardwareKey, 10), val => (val || '').length === 10)
		.matches(/^[1-9][0-9]*$/, errorMessages.hardwareKeyValidate()),
	// name1: Yup.string().required(errorMessages.required(localizationConstants.name1)).strict(true),
	// name2: Yup.string().required(errorMessages.required(localizationConstants.name2)).strict(true),
	// street: Yup.string().strict(true),
	// zipCode: Yup.string().required(errorMessages.required(localizationConstants.zipCode)).strict(true),
	// city: Yup.string().required(errorMessages.required(localizationConstants.city)).strict(true),
	// nation: Yup.string().strict(true),
	// phone: Yup.string().required(errorMessages.required(localizationConstants.phone)).strict(true),
	// fax: Yup.string().strict(true),
	email: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email)),
	// ust: Yup.string().strict(true),
	expiryDate: Yup.date().when("expiryDateType", {
		is: ((value: string) => value === localizationConstants.custom),
		then: Yup.date().nullable().required(errorMessages.required(localizationConstants.expiryDate))
	}),
	[localizationConstants.activateAutoLicense]: Yup.array(),
	licenseVersion: Yup.string().required(errorMessages.select(localizationConstants.version)).strict(true),
	typeValue: Yup.number().when("expiryDateType", {
		is: ((value: string) => value === localizationConstants.year),
		then: Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.required(errorMessages.required(localizationConstants.value))
	}).when("expiryDateType", {
		is: ((value: string) => value === localizationConstants.month),
		then: Yup.number()
			.min(1, errorMessages.minValue(localizationConstants.value, 1))
			.max(12, errorMessages.maxValue(localizationConstants.value, 12))
			.required(errorMessages.required(localizationConstants.value))
	})
});

const getEmailTemplateFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
	subject: Yup.string().min(2, errorMessages.minLength(localizationConstants.subject, 2))
		.required(errorMessages.required(localizationConstants.subject)).strict(true)
});

const getLoginFormValidationSchema = () => Yup.object().shape({
	email: Yup.string().email()
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	password: Yup.string()
		.required(errorMessages.required(localizationConstants.password)).strict(true)
});

const getRegisterUserValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
	email: Yup.string().email()
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	password: Yup.string().min(8, errorMessages.minLength(localizationConstants.password, 8))
		.required(errorMessages.required(localizationConstants.password)).strict(true),
	confirmPassword: Yup.string()
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.password
		}),
	debAuto: Yup.number()
		.min(1, errorMessages.minValue(localizationConstants.debAuto, 1))
		.max(99999, errorMessages.maxValue(localizationConstants.debAuto, 99999))
		.required(errorMessages.required(localizationConstants.debAuto)).strict(true)
});

const getForgetPasswordValidationSchema = () => Yup.object().shape({
	email: Yup.string().email()
		.required(errorMessages.required(localizationConstants.email)).strict(true),
});

const getResetPasswordValidationSchema = () => Yup.object().shape({
	// email: Yup.string().email()
	// 	.required(errorMessages.required('email')).strict(true),
	password: Yup.string().min(8, errorMessages.minLength(localizationConstants.newPassword, 8))
		.required(errorMessages.required(localizationConstants.newPassword)).strict(true),
	password_confirmation: Yup.string().min(8, errorMessages.minLength(localizationConstants.passwordConfirmation, 8))
		.required(errorMessages.required('Password Confirmation')).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.password
		})
});

const getResetMasterPasswordValidationSchema = () => Yup.object().shape({
	newPassword: Yup.string().min(8, errorMessages.minLength(localizationConstants.newPassword, 8))
		.required(errorMessages.required(localizationConstants.newPassword)).strict(true),
	confirmPassword: Yup.string().min(8, errorMessages.minLength(localizationConstants.confirmPassword, 8))
		.required(errorMessages.required(localizationConstants.confirmPassword)).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.newPassword
		})
});

const getChangePasswordValidationSchema = () => Yup.object().shape({
	old_password: Yup.string().required(errorMessages.required(localizationConstants.oldPassword)).strict(true),
	new_password: Yup.string().min(8, errorMessages.minLength(localizationConstants.oldPassword, 8))
		.required(errorMessages.required(localizationConstants.newPassword)).strict(true),
	new_password_confirmation: Yup.string().min(8, errorMessages.minLength(localizationConstants.passwordConfirmation, 8))
		.required(errorMessages.required(localizationConstants.passwordConfirmation)).strict(true)
		.test('match', errorMessages.passwordMatchValidate(), function (password) {
			return password === this.parent.new_password
		})
});

const getUpdateProfileValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true),
	email: Yup.string().email()
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	language: Yup.string()
		.required(errorMessages.required(localizationConstants.language)).strict(true),
	deb_auto_id: Yup.number()
		.min(1, errorMessages.minValue(localizationConstants.debAuto, 1))
		.max(99999, errorMessages.maxValue(localizationConstants.debAuto, 99999))
		.required(errorMessages.required(localizationConstants.debAuto)).strict(true)
});


const getRoleFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
});

const getUserFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
	email: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email))
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	password: Yup.string()
		.required(errorMessages.required(localizationConstants.password)).strict(true),
	role: Yup.string().required(errorMessages.select(localizationConstants.role)).strict(true),
	status: Yup.string().required(errorMessages.select(localizationConstants.status)).strict(true),
});

const getEditUserFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().min(2, errorMessages.minLength(localizationConstants.name, 2))
		.required(errorMessages.required(localizationConstants.name)).strict(true),
	email: Yup.string().email(errorMessages.validType(localizationConstants.email, localizationConstants.email))
		.required(errorMessages.required(localizationConstants.email)).strict(true),
	password: Yup.string().strict(true),
	role: Yup.string().required(errorMessages.select(localizationConstants.role)),
	status: Yup.string().required(errorMessages.select(localizationConstants.status)),
});

const getNewCustomerValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true),
	street: Yup.string().required(errorMessages.required(localizationConstants.street)).strict(true),
	zipCode: Yup.string().required(errorMessages.required(localizationConstants.zipCode)).strict(true),
	city: Yup.string().required(errorMessages.required(localizationConstants.city)).strict(true),
});

const getAddStoreValidationSchema = () => Yup.object().shape({
	domain: Yup.string()
		.required(errorMessages.required(localizationConstants.domain)).strict(true)
		.matches(/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.(myshopify)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, errorMessages.domainMatchValidate())
});

const getAddCredentialValidationSchema = () => Yup.object().shape({
	notes: Yup.string().required(errorMessages.required(localizationConstants.notes)).strict(true),
	type: Yup.string().required(errorMessages.select(localizationConstants.type)),
	status: Yup.string().required(errorMessages.select(localizationConstants.status)),
	apiId: Yup.string().required(errorMessages.required(localizationConstants.apiId)).strict(true),
	apiSecretKey: Yup.string().required(errorMessages.required(localizationConstants.apiSecretKey)).strict(true),
});

const getUpdateCredentialValidationSchema = () => Yup.object().shape({
	notes: Yup.string().required(errorMessages.required(localizationConstants.notes)).strict(true),
	status: Yup.string().required(errorMessages.select(localizationConstants.status)),
});

const getModuleValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true),
	price: Yup.number()
		.min(0, errorMessages.minValue(localizationConstants.price, 0))
		.required(errorMessages.required(localizationConstants.price)),
});

const getPackageValidationSchema = () => Yup.object().shape({
	name: Yup.string().required(errorMessages.required(localizationConstants.name)).strict(true)
});

const getPhoenixLicenseManageSubscriptionValidationSchema = () => Yup.object().shape({
	version: Yup.string().required(errorMessages.select(localizationConstants.version)).strict(true),
});

let sendEmailValidationSchema = getSendEmailValidationSchema();

let addLinceseFormValidationSchema = getAddLinceseFormValidationSchema();

let addHardwareValidationSchema = getAddHardwareValidationSchema();

let emailTemplateFormValidationSchema = getEmailTemplateFormValidationSchema();

let loginFormValidationSchema = getLoginFormValidationSchema();

let registerUserValidationSchema = getRegisterUserValidationSchema();

let forgetPasswordValidationSchema = getForgetPasswordValidationSchema();

let resetPasswordValidationSchema = getResetPasswordValidationSchema();

let changePasswordValidationSchema = getChangePasswordValidationSchema();

let updateProfileValidationSchema = getUpdateProfileValidationSchema();

let roleFormValidationSchema = getRoleFormValidationSchema();

let userFormValidationSchema = getUserFormValidationSchema();

let editUserFormValidationSchema = getEditUserFormValidationSchema();

let newCustomerValidationSchema = getNewCustomerValidationSchema();

let addStoreValidationSchema = getAddStoreValidationSchema();

let addCredentialValidationSchema = getAddCredentialValidationSchema();

let updateCredentialValidationSchema = getUpdateCredentialValidationSchema();

let moduleValidationSchema = getModuleValidationSchema();

let packageValidationSchema = getPackageValidationSchema();

let resetMasterPasswordValidationSchema = getResetMasterPasswordValidationSchema();

let phoenixLicenseManageSubscriptionValidationSchema = getPhoenixLicenseManageSubscriptionValidationSchema();

i18n.on('languageChanged', () => {
	sendEmailValidationSchema = getSendEmailValidationSchema();
	addLinceseFormValidationSchema = getAddLinceseFormValidationSchema();
	addHardwareValidationSchema = getAddHardwareValidationSchema();
	emailTemplateFormValidationSchema = getEmailTemplateFormValidationSchema();
	loginFormValidationSchema = getLoginFormValidationSchema();
	registerUserValidationSchema = getRegisterUserValidationSchema();
	forgetPasswordValidationSchema = getForgetPasswordValidationSchema();
	resetPasswordValidationSchema = getResetPasswordValidationSchema();
	changePasswordValidationSchema = getChangePasswordValidationSchema();
	updateProfileValidationSchema = getUpdateProfileValidationSchema();
	roleFormValidationSchema = getRoleFormValidationSchema();
	userFormValidationSchema = getUserFormValidationSchema();
	editUserFormValidationSchema = getEditUserFormValidationSchema();
	newCustomerValidationSchema = getNewCustomerValidationSchema();
	addStoreValidationSchema = getAddStoreValidationSchema();
	addCredentialValidationSchema = getAddCredentialValidationSchema();
	updateCredentialValidationSchema = getUpdateCredentialValidationSchema();
	moduleValidationSchema = getModuleValidationSchema();
	packageValidationSchema = getPackageValidationSchema();
	resetMasterPasswordValidationSchema = getResetMasterPasswordValidationSchema();
	phoenixLicenseManageSubscriptionValidationSchema = getPhoenixLicenseManageSubscriptionValidationSchema();
});

export {
	sendEmailValidationSchema,
	addLinceseFormValidationSchema,
	addHardwareValidationSchema,
	emailTemplateFormValidationSchema,
	loginFormValidationSchema,
	registerUserValidationSchema,
	forgetPasswordValidationSchema,
	resetPasswordValidationSchema,
	changePasswordValidationSchema,
	updateProfileValidationSchema,
	roleFormValidationSchema,
	getSchema,
	userFormValidationSchema,
	editUserFormValidationSchema,
	newCustomerValidationSchema,
	addStoreValidationSchema,
	addCredentialValidationSchema,
	updateCredentialValidationSchema,
	moduleValidationSchema,
	packageValidationSchema,
	resetMasterPasswordValidationSchema,
	phoenixLicenseManageSubscriptionValidationSchema
};
