import { ThunkDispatch } from 'redux-thunk';
import filter from 'lodash/filter';

import * as actionTypes from 'store/action-types';
import AuthService from 'shared/services/auth.service';
import HttpService from 'shared/services/http.service';
import { API_CONFIG, allPermissionMapping } from 'shared/constants/constants';
import { createAction } from 'shared/util/utility';
import { Action } from 'shared/interface';
import { refreshLanguage } from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { LoginResponse, UserLoginData, UserProfileResponse, SideBarMenu, ChangePasswordParams, UpdateProfileParams } from '../interface/login.interface';

/**
 * getSideNavMenu - returns side nav menu from the permissions response
 * @param profileResponse 
 */
const getSideNavMenu = (profileResponse: UserProfileResponse) => {
	const sidebarMenu: SideBarMenu[] = [];
	const permissions = (!!profileResponse.user.permissions && Object.keys(profileResponse.user.permissions)) || {};
	const sidebarItems = [
		// localizationConstants.dashboardTitle,
		// localizationConstants.machineListTitle,
		// localizationConstants.companyListTitle,
		localizationConstants.terracloud,
		localizationConstants.posbillBackup
	];
	sidebarItems.forEach(sidebarItem => {
		const allPermissions = filter(permissions, p => {
			//search for permissions which contain either of ['list','create']
			return p.indexOf(`${sidebarItem}.`) === 0
		}).map(permission => {
			//users.create => 'create'
			return permission.split('.')[1]
		});

		let children;
		let name = sidebarItem;
		let route = sidebarItem;
		switch (name) {
			case localizationConstants.dashboardTitle:
				route = '';
				break;
			case localizationConstants.machineListTitle:
				route = 'machine/list';
				if (!permissions.includes(allPermissionMapping['customerLicenseMachineList'])) {
					return;
				}
				break;
			case localizationConstants.companyListTitle:
				route = 'company/list';
				if (!permissions.includes(allPermissionMapping['phoenixCompanyList'])) {
					return;
				}
				break;
			case localizationConstants.terracloud:
				children=[];
				route = 'tse-backup';
				// if (permissions.includes(allPermissionMapping['apiKeyList'])) {
					children.push({ name: localizationConstants.apiKeyListTitle, url: '/tse-backup/api-key/list' });
				// }
				if (permissions.includes(allPermissionMapping['tseBackupHistoryList'])) {
					children.push({ name: localizationConstants.backups, url: '/tse-backup/history' });
				}
				break;
			case localizationConstants.posbillBackup:
				children = [];
				route = 'posbill-backups';
				if (permissions.includes(allPermissionMapping['posbillBackupApiKeyList'])) {
					children.push({ name: localizationConstants.apiKeyListTitle, url: '/posbill-backups/api-key/list' });
				}
				if (permissions.includes(allPermissionMapping['posbillBackupListBackups'])) {
					children.push({ name: localizationConstants.backups, url: '/posbill-backups/list' });
				}
				if (permissions.includes(allPermissionMapping['posbillBackupListBackups'])) {
					children.push({ name: localizationConstants.downloadRequests, url: '/posbill-backups/download-requests' });
				}
				if (!children.length) {
					return;
				}
				break;
		}
		sidebarMenu.push({
			name: name,
			permissionName: sidebarItem,
			reactRoute: `/${route}`,
			permissions: allPermissions,
			children: children
		});
	});
	return sidebarMenu;
}

/**
 * User Login
 * @param data  - contains email & password of user
 */
const login = (data: UserLoginData) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.AUTH_INIT));
		HttpService.post(API_CONFIG.path.login, data, {}, {
			isAccessTokenRequire: false
		}).then((response: LoginResponse) => {
			// set authData in local storage after successful login
			AuthService.setAuthData(response);
			HttpService.get(API_CONFIG.path.profile).then((profileResponse: UserProfileResponse) => {
				// build sideNav Menu from received profile response
				profileResponse.sidebarMenu = getSideNavMenu(profileResponse);
				AuthService.setUserData(profileResponse);
				refreshLanguage(profileResponse.user.language || 'de');
				dispatch(createAction(actionTypes.AUTH_SUCCESS, profileResponse));
				dispatch(createAction('RESET_MODAL'));
			});
		}).catch(() => {
			dispatch(createAction(actionTypes.AUTH_FAIL));
		});
	};
}

/**
 * getProfile - get users' profile & set profile data in local storage
 */
const getProfile = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.GET_PROFILE_INIT));
		HttpService.get(API_CONFIG.path.profile).then((profileResponse: UserProfileResponse) => {
			// build sideNav Menu from received profile response
			profileResponse.sidebarMenu = getSideNavMenu(profileResponse);
			// set userData in local storage after successful profile response
			AuthService.setUserData(profileResponse);
			refreshLanguage(profileResponse.user.language || 'de');
			dispatch(createAction(actionTypes.GET_PROFILE_SUCCESS, profileResponse));
		}).catch(() => {
			dispatch(createAction(actionTypes.GET_PROFILE_FAIL));
		});
	};
}

/**
 * changePassword - action to change logged in user's password
 * @param passwords - old password, new password & new password confirmation
 */
const changePassword = (passwords: ChangePasswordParams) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.CHANGE_PASSWORD_INIT));
		HttpService.post(API_CONFIG.path.changePassword, passwords).then(() => {
			dispatch(createAction(actionTypes.CHANGE_PASSWORD_SUCCESS));
			// reset modal after reset password is succesful, in order to close change password modal
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.CHANGE_PASSWORD_FAIL));
		});
	};
}

/**
 * logout - logout action for logged in user
 */
const logout = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.AUTH_LOGOUT_INIT));
		HttpService.get(API_CONFIG.path.logout)
			.then(() => dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS)))
			.catch(() => dispatch(createAction(actionTypes.AUTH_LOGOUT_FAIL)));
	};
}

/**
 * updateProfile - action to update logged in user's profile
 * @param profile - contains user name, language & email
 */
const updateProfile = (profile: UpdateProfileParams) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.UPDATE_PROFILE_INIT));
		HttpService.put(API_CONFIG.path.profile, profile).then((response: any) => {
			const profileResponse: any = {
				user: response,
				sidebarMenu: []
			};
			// build sideNav Menu from received profile response
			profileResponse.sidebarMenu = getSideNavMenu(profileResponse);
			// set userData in local storage after successful profile response
			AuthService.setUserData(profileResponse);
			refreshLanguage(profileResponse.user.language || 'de');
			dispatch(createAction(actionTypes.UPDATE_PROFILE_SUCCESS, profileResponse));
			// reset modal after reset password is succesful, in order to close update profile modal
			dispatch(createAction('RESET_MODAL'));
		}).catch(() => {
			dispatch(createAction(actionTypes.UPDATE_PROFILE_FAIL));
		});
	};
}

export {
	login,
	logout,
	getProfile,
	changePassword,
	updateProfile,
	getSideNavMenu,
}

