const localizationConstants = {
	dashboardTitle: 'dashboardTitle',
	autoGeneratedLicenseGraphTitle: 'autoGeneratedLicenseGraphTitle',
	upcomingCreatedLicenseGraphTitle: 'upcomingCreatedLicenseGraphTitle',
	daily: 'daily',
	weekly: 'weekly',
	monthly: 'monthly',
	today: 'today',
	yesterday: 'yesterday',
	thisWeek: 'thisWeek',
	lastWeek: 'lastWeek',
	thisMonth: 'thisMonth',
	lastMonth: 'lastMonth',
	custom: 'custom',
	backBtnText: 'backBtnText',
	organizationsTitle: 'organizationsTitle',
	superAdmin: 'superAdmin',
	license: 'license',
	support: 'support',
	licenses: 'licenses',
	incomingLicensesTitle: 'incomingLicensesTitle',
	machineListTitle: 'machineListTitle',
	licenseNumberView: 'licenseNumberView',
	users: 'users',
	user: 'user',
	list: 'list',
	create: 'create',
	rolesAndPermissions: 'rolesAndPermissions',
	roles: 'roles',
	role: 'role',
	status: 'status',
	permissions: 'permissions',
	addUser: 'addUser',
	supportList: 'supportList',
	licenseList: 'licenseList',
	superAdminList: 'superAdminList',
	logout: 'logout',
	home: 'home',
	view: 'view',
	createLicense: 'createLicense',
	delete: 'delete',
	restore: 'restore',
	edit: 'edit',
	all: 'all',
	customer: 'customer',
	customers: 'customers',
	leads: 'leads',
	search: 'search',
	licenseNumbers: 'licenseNumbers',
	licenseInformation: 'licenseInformation',
	organizationDetails: 'organizationDetails',
	details: 'details',
	profile: 'profile',
	editUser: 'editUser',
	notFoundMsg: 'notFoundMsg',
	pageNotFound: 'pageNotFound',
	actions: 'actions',
	orgTimeline: 'orgTimeline',
	noDataFoundText: 'noDataFoundText',
	serialNumber: 'serialNumber',
	name: 'name',
	email: 'email',
	recipientEmail: 'recipientEmail',
	senderEmail: 'senderEmail',
	password: 'password',
	oldPassword: 'oldPassword',
	newPassword: 'newPassword',
	confirmPassword: 'confirmPassword',
	passwordConfirmation: 'passwordConfirmation',
	debAuto: 'debAuto',
	createdAt: 'createdAt',
	updatedAt: 'updatedAt',
	difference: 'difference',
	functions: 'functions',
	hardwareKey: 'hardwareKey',
	hardwareId: 'hardwareId',
	timestampInsert: 'timestampInsert',
	timestampUpdate: 'timestampUpdate',
	activeLicense: 'activeLicense',
	nextLicense: 'nextLicense',
	posbillVersion: 'Posbill Version',
	partnerId: 'partnerId',
	branchId: 'branchId',
	isLocked: 'isLocked',
	emailVerifiedAt: 'emailVerifiedAt',
	zip: 'zip',
	telefax: 'telefax',
	mobile: 'mobile',
	website: 'website',
	billingPeriod: 'billingPeriod',
	expiringDate: 'expiringDate',
	licenseNumber: 'licenseNumber',
	ustId: 'ustId',
	iban: 'iban',
	bic: 'bic',
	bank: 'bank',
	saleTaxId: 'saleTaxId',
	vatNumber: 'vatNumber',
	salesForceId: 'salesForceId',
	acceptAgbDate: 'acceptAgbDate',
	acceptSwnDate: 'acceptSwnDate',
	expiryDateType: 'expiryDateType',
	year: 'year',
	month: 'month',
	typeValue: 'typeValue',
	value: 'value',
	expiryDate: 'expiryDate',
	autoLicense: 'autoLicense',
	version: 'version',
	autoUpdate: 'autoUpdate',
	validFrom: 'validFrom',
	validTo: 'validTo',
	newLicense: 'newLicense',
	manageModules: 'manageModules',
	lock: 'lock',
	locked: 'locked',
	unlock: 'unlock',
	licenseLock: 'licenseLock',
	licenseUnlock: 'licenseUnlock',
	machineLock: 'machineLock',
	machineUnlock: 'machineUnlock',
	licenseLocked: 'licenseLocked',
	licenseUnlocked: 'licenseUnlocked',
	machineLocked: 'machineLocked',
	machineUnlocked: 'machineUnlocked',
	machineAutoLicenseEnabled: 'machineAutoLicenseEnabled',
	machineAutoLicenseDisabled: 'machineAutoLicenseDisabled',
	sendLicenseEmail: 'sendLicenseEmail',
	generate: 'generate',
	copyEmailAndPasswordToClipboard: 'copyEmailAndPasswordToClipboard',
	submit: 'submit',
	submitWithEmail: 'submitWithEmail',
	cancel: 'cancel',
	yes: 'yes',
	no: 'no',
	archived: 'archived',
	active: 'active',
	Active: 'Active',
	addNewUser: 'addNewUser',
	id: 'id',
	module: 'module',
	close: 'close',
	noUsersAvailable: 'noUsersAvailable',
	slug: 'slug',
	language: 'language',
	german: 'german',
	english: 'english',
	noPermissionsAvailable: 'noPermissionsAvailable',
	changePassword: 'changePassword',
	updateProfileTitle: 'updateProfileTitle',
	changePasswordTitle: 'changePasswordTitle',
	editUserDetails: 'editUserDetails',
	newRegisteredUser: 'newRegisteredUser',
	inActive: 'inActive',
	blocked: 'blocked',
	incomingLicenseList: 'incomingLicenseList',
	licenseNumbersList: 'licenseNumbersList',
	forgetPassword: 'forgetPassword',
	getPasswordResetCode: 'getPasswordResetCode',
	backToLogin: 'backToLogin',
	noMachinesFound: 'noMachinesFound',
	resetYourPassword: 'resetYourPassword',
	resetPassword: 'resetPassword',
	login: 'login',
	register: 'register',
	privacyPolicy: 'privacyPolicy',
	hello: 'hello',
	licenseDetailsAreHere: 'licenseDetailsAreHere',
	thankYou: 'thankYou',
	yourPosbillTeam: 'yourPosbillTeam',
	licenseReport: 'licenseReport',
	standard: 'standard',
	premium: 'premium',
	demo: 'demo',
	fun: 'fun',
	default: 'default',
	sql: 'sql',
	addItem: 'addItem',
	OK: 'OK',
	addLicense: 'addLicense',
	addHardware: 'addHardware',
	add: 'add',
	addWithEmail: 'addWithEmail',
	send: 'send',
	openEmail: 'openEmail',
	addNewRole: 'addNewRole',
	editRole: 'editRole',
	customerData: 'customerData',
	name1: 'name1',
	name2: 'name2',
	street: 'street',
	zipCode: 'zipCode',
	city: 'city',
	nation: 'nation',
	phone: 'phone',
	fax: 'fax',
	ust: 'ust',
	expiryDateValidate: 'expiryDateValidate',
	activateAutoLicense: 'Activate Auto License',
	to: 'to',
	cc: 'cc',
	subject: 'subject',
	content: 'content',
	requiredFieldValidation: 'requiredFieldValidation',
	fieldSelectionValidation: 'fieldSelectionValidation',
	exactLengthValidation: 'exactLengthValidation',
	minLengthStrValidation: 'minLengthStrValidation',
	minLengthArrValidation: 'minLengthArrValidation',
	maxLengthStrValidation: 'maxLengthStrValidation',
	maxLengthArrValidation: 'maxLengthArrValidation',
	minValueValidation: 'minValueValidation',
	maxValueValidation: 'maxValueValidation',
	typeValidation: 'typeValidation',
	hardwareKeyValidation: 'hardwareKeyValidation',
	passwordMatchValidation: 'passwordMatchValidation',
	domainMatchValidation: 'domainMatchValidation',
	confirmRestoreMsg: 'confirmRestoreMsg',
	confirmDeleteMsg: 'confirmDeleteMsg',
	confirmLogoutMsg: 'confirmLogoutMsg',
	confirmAutoLicense: 'confirmAutoLicense',
	confirmChangeLicenseVersion: 'confirmChangeLicenseVersion',
	confirmChangeLockStatus: 'confirmChangeLockStatus',
	confirmChangeMachineLockStatus: 'confirmChangeMachineLockStatus',
	confirmMachineAutoLicense: 'confirmMachineAutoLicense',
	noLicensesFound: 'noLicensesFound',
	noExpiredLicensesFound: 'noExpiredLicensesFound',
	addHardwareNotAllowed: 'addHardwareNotAllowed',
	customerIdRequirements: 'customerIdRequirements',
	givenCustomerIdIsNotValid: 'givenCustomerIdIsNotValid',
	relateMachineToCustomer: 'relateMachineToCustomer',
	newCustomer: 'newCustomer',
	relate: 'relate',
	customerId: 'customerId',
	clear: 'clear',
	copied: 'copied',
	copyText: 'copyText',
	totalRecords: 'totalRecords',

	birdhub: 'birdhub',
	birdhubTitle: 'birdhubTitle',
	stores: 'stores',
	storeList: 'storeList',
	inventory: 'inventory',
	shopDetail: 'shopDetail',
	backToShopify: 'backToShopify',
	connectWithShopify: 'connectWithShopify',
	connectToShopify: 'connectToShopify',
	addShop: 'addShop',
	addNewShop: 'addNewShop',
	shopName: 'shopName',
	domain: 'domain',
	currency: 'currency',
	plan: 'plan',
	info: 'info',
	marketplace: 'marketplace',
	itemId: 'itemId',
	itemName: 'itemName',
	customerName: 'customerName',
	customerEmail: 'customerEmail',
	orderName: 'orderName',
	orderDate: 'orderDate',
	totalPrice: 'totalPrice',
	quantity: 'quantity',
	itemPrice: 'itemPrice',
	purchasePrice: 'purchasePrice',
	itemDetail: 'itemDetail',
	stockDetail: 'stockDetail',
	variantId: 'variantId',
	locationId: 'locationId',
	stock: 'stock',
	reserveStock: 'reserveStock',
	reorderStock: 'reorderStock',
	syncOrders: 'syncOrders',
	shopUninstalledText: 'shopUninstalledText',
	initialSetup: 'initialSetup',
	birdhubSetup: 'birdhubSetup',
	registerWebhooks: 'registerWebhooks',
	webhookInstall: 'webhookInstall',
	initialSync: 'initialSync',
	locations: 'Locations',
	product: 'Product',
	orders: 'Orders',
	syncLocation: 'syncLocation',
	syncProduct: 'syncProduct',
	initialSetupInfoText: 'initialSetupInfoText',
	registerWebhookInfoText: 'registerWebhookInfoText',
	initialSyncInfoText: 'initialSyncInfoText',
	note: 'note',
	uninstall: 'uninstall',
	registerWebhookText: 'registerWebhookText',
	uninstallWebhookText: 'uninstallWebhookText',
	registerWebhookNote: 'registerWebhookNote',
	uninstallWebhookNote: 'uninstallWebhookNote',

	internal: 'internal',
	global: 'global',
	local: 'local',
	birdhubAdmin: 'birdhubAdmin',
	birdhubAdminTitle: 'birdhubAdminTitle',
	credentials: 'credentials',
	credentialList: 'credentialList',
	addNewCredential: 'addNewCredential',
	addCredential: 'addCredential',
	updateCredential: 'updateCredential',
	credentialInfo: 'credentialInfo',
	apiId: 'apiId',
	apiSecretKey: 'apiSecretKey',
	type: 'type',
	notes: 'notes',

	phoenixAdmin: 'phoenixAdmin',
	phoenixAdminTitle: 'phoenixAdminTitle',
	companyList: 'companyList',
	companyDetail: 'companyDetail',
	companyId: 'companyId',
	phoenixIncomingLicenseList: 'phoenixIncomingLicenseList',
	phoenixIncomingLicenseTitle: 'phoenixIncomingLicenseTitle',
	isEmailVerified: 'isEmailVerified',
	verified: 'verified',
	subscriptions: 'subscriptions',
	subscription: 'subscription',
	subscriptionId: 'subscriptionId',
	additionalUsers: 'additionalUsers',
	additionalFeatures: 'additionalFeatures',
	bankInfo: 'bankInfo',
	universeId: 'universeId',
	companyName: 'companyName',
	isVerified: 'isVerified',
	manageSubscription: 'manageSubscription',
	resetMasterPassword: 'resetMasterPassword',
	companyLocked: 'companyLocked',
	companyUnlocked: 'companyUnlocked',
	confirmChangeCompanyLockStatus: 'confirmChangeCompanyLockStatus',

	modules: 'modules',
	moduleList: 'moduleList',
	addNewModule: 'addNewModule',
	addModule: 'addModule',
	updateModule: 'updateModule',
	price: 'price',

	package: 'package',
	packages: 'packages',
	packageList: 'packageList',
	updatePackage: 'updatePackage',

	startPhoenixNow: 'startPhoenixNow',
	companyAlreadyValidatedMessage: 'companyAlreadyValidatedMessage',
	companyVerificationSuccessMessage: 'companyVerificationSuccessMessage',
	companyVerificationFailMessage: 'companyVerificationFailMessage',

	emailAlreadyValidatedMessage: 'emailAlreadyValidatedMessage',
	emailVerificationSuccessMessage: 'emailVerificationSuccessMessage',
	emailVerificationFailMessage: 'emailVerificationFailMessage',

	companyListTitle: 'companyListTitle',
	hospitality: 'hospitality',
	retail: 'retail',
	shopListTitle: 'shopListTitle',
	backToHome: 'backToHome',
	posbillConnect: 'posbillConnect',
	isRequestingPermission: 'isRequestingPermission',
	readMyProductInformation: 'readMyProductInformation',
	accesssToProductProductVariant: 'accesssToProductProductVariant',
	readMyOrderInfo: 'readMyOrderInfo',
	accesssToAbandonedCheckouts: 'accesssToAbandonedCheckouts',
	readMyInventoryInfo: 'readMyInventoryInfo',
	accessToInventoryLevel: 'accessToInventoryLevel',
	readMyLocationInfo: 'readMyLocationInfo',
	accessToLocation: 'accessToLocation',
	readMyCustomerInfo: 'readMyCustomerInfo',
	accessToCustomerAndSavedSearch: 'accessToCustomerAndSavedSearch',
	deny: 'deny',
	allow: 'allow',
	invalidToken: 'invalidToken',
	shopifyInstallationCalcelled: 'shopifyInstallationCalcelled',
	contineWith: 'contineWith',
	loginWithDifferentAccount: 'loginWithDifferentAccount',
	signup: 'signup',
	authorizeUserInfoText: 'authorizeUserInfoText',
	additionalInformation: 'additionalInformation',
	shopNotFound: 'shopNotFound',

	terracloud: 'terracloud',
	terracloudBackupList: 'terracloudBackupList',
	download: 'download',
	directoryName: 'directoryName',
	lastModifiedDate: 'lastModifiedDate',
	fileName: 'fileName',
	fileSize: 'fileSize',
	serial_number: 'serial_number',
	checksum: 'checksum',
	workingDate: 'workingDate',
	progressing: 'progressing',
	inProgress: 'inProgress',
	uploaded: 'uploaded',
	downloaded: 'downloaded',
	createDownloadRequest: 'createDownloadRequest',
	activeBackupData: 'activeBackupData',
	backupInProgressText: 'backupInProgressText',
	backupReadyText: 'backupReadyText',
	backupFiles: 'backupFiles',
	generateDownloadLink: 'generateDownloadLink',
	generatedLinkText: 'generatedLinkText',
	downloadBackup: 'downloadBackup',
	confirmCreateDownloadRequest: 'confirmCreateDownloadRequest',
	backups: 'backups',

	apiKeyListTitle: 'apiKeyListTitle',
	generateNewKey: 'generateNewKey',
	generateAPIKey: 'generateAPIKey',
	done: 'done',
	copyToClipboard: 'copyToClipboard',
	
	posbillBackup: 'posbillBackup',
	generatePosbillBackupAPIKey: 'generatePosbillBackupAPIKey',
	posbillBackupList: 'posbillBackupList',
	pending: 'pending',
	failed: 'failed',
	requestDownload: 'requestDownload',
	requested: 'requested',
	notUploaded: 'notUploaded',

	downloadRequests: 'downloadRequests',
	downloadRequestList: 'downloadRequestList',
	completed: 'completed',
	expired: 'expired',

	'permission-module-Organizations': 'permission-module-Organizations',
	'permission-module-Widgets': 'permission-module-Widgets',
	'permission-module-Roles': 'permission-module-Roles',
	'permission-module-Users': 'permission-module-Users',
	'permission-module-EmailTemplates': 'permission-module-EmailTemplates',
	'permission-module-EmailTemplateVariables': 'permission-module-EmailTemplateVariables',
	'permission-module-Permissions': 'permission-module-Permissions',
	'permission-module-Licenses': 'permission-module-Licenses',
	'permission-module-CustomerLicense': 'permission-module-CustomerLicense',
	'permission-module-Graphs': 'permission-module-Graphs',
	'permission-module-BirdHubCredentials': 'permission-module-BirdHubCredentials',
	'permission-module-PhoenixModules': 'permission-module-PhoenixModules',
	'permission-module-PhoenixCompanies': 'permission-module-PhoenixCompanies',
	'permission-module-CustomerPhoenixLicense': 'permission-module-CustomerPhoenixLicense',
	'permission-module-PhoenixPackages': 'permission-module-PhoenixPackages',
	'organizations.superAdminIndex': 'organizations.superAdminIndex',
	'organizations.licenseIndex': 'organizations.licenseIndex',
	'organizations.supportIndex': 'organizations.supportIndex',
	'organizations.show': 'organizations.show',
	'organizations.timeline': 'organizations.timeline',
	'organizations.superAdminShow': 'organizations.superAdminShow',
	'organizations.licenseShow': 'organizations.licenseShow',
	'organizations.supportShow': 'organizations.supportShow',
	'incomingLicense.incomingRequestList': 'incomingLicense.incomingRequestList',
	'incomingLicense.incomingAssignCustomer': 'incomingLicense.incomingAssignCustomer',
	'incomingLicense.createNewCustomer': 'incomingLicense.createNewCustomer',
	'widget.userStatistics': 'widget.userStatistics',
	'roles.index': 'roles.index',
	'roles.store': 'roles.store',
	'roles.show': 'roles.show',
	'roles.update': 'roles.update',
	'roles.destroy': 'roles.destroy',
	'users.index': 'users.index',
	'users.store': 'users.store',
	'users.archive': 'users.archive',
	'users.show': 'users.show',
	'users.update': 'users.update',
	'users.destroy': 'users.destroy',
	'users.restore': 'users.restore',
	'emailTemplate.index': 'emailTemplate.index',
	'emailTemplate.store': 'emailTemplate.store',
	'emailTemplate.show': 'emailTemplate.show',
	'emailTemplate.update': 'emailTemplate.update',
	'emailTemplate.destroy': 'emailTemplate.destroy',
	'emailTemplateVariable.store': 'emailTemplateVariable.store',
	'emailTemplateVariable.destroy': 'emailTemplateVariable.destroy',
	'permissions.index': 'permissions.index',
	'licenses.incoming': 'licenses.incoming',
	'licenses.machine_list': 'licenses.machine_list',
	'licenses.license_numbers': 'licenses.license_numbers',
	'customerLicense.machineIndex': 'customerLicense.machineIndex',
	'customerLicense.machineStore': 'customerLicense.machineStore',
	'customerLicense.machineShow': 'customerLicense.machineShow',
	'customerLicense.machineUpdate': 'customerLicense.machineUpdate',
	'customerLicense.machineLicenses': 'customerLicense.machineLicenses',
	'customerLicense.licenseStore': 'customerLicense.licenseStore',
	'customerLicense.sendEmail': 'customerLicense.sendEmail',
	'customerLicense.moduleList': 'customerLicense.moduleList',
	'customerLicense.moduleStore': 'customerLicense.moduleStore',
	'customerLicense.moduleUpdate': 'customerLicense.moduleUpdate',
	'customerLicense.licenseUpdate': 'customerLicense.licenseUpdate',

	'graphs.autoGeneratedLicenseGraph': 'graphs.autoGeneratedLicenseGraph',
	'graphs.upcomingCreatedLicenses': 'graphs.upcomingCreatedLicenses',

	'bird_hub.credentials.index': 'bird_hub.credentials.index',
	'bird_hub.credentials.show': 'bird_hub.credentials.show',
	'bird_hub.credentials.store': 'bird_hub.credentials.store',
	'bird_hub.credentials.update': 'bird_hub.credentials.update',

	'phoenix.incomingLicenseRequestList': 'phoenix.incomingLicenseRequestList',
	'phoenix.incomingAssignCustomer': 'phoenix.incomingAssignCustomer',
	'phoenix.createNewCustomer': 'phoenix.createNewCustomer',
	'phoenix.modules.index': 'phoenix.modules.index',
	'phoenix.modules.store': 'phoenix.modules.store',
	'phoenix.modules.update': 'phoenix.modules.update',
	'phoenix.modules.destroy': 'phoenix.modules.destroy',
	'phoenix.companies.index': 'phoenix.companies.index',
	'phoenix.companies.show': 'phoenix.companies.show',
	'phoenix.subscriptions.index': 'phoenix.subscriptions.index',
	'phoenix.subscriptions.show': 'phoenix.subscriptions.show',

	'phoenix.packages.index': 'phoenix.packages.index',
	'phoenix.packages.update': 'phoenix.packages.update',

	'customerPhoenixLicense.changeMasterPassword': 'customerPhoenixLicense.changeMasterPassword',
	'customerPhoenixLicense.companyIndex': 'customerPhoenixLicense.companyIndex',
	'customerPhoenixLicense.companyUpdate': 'customerPhoenixLicense.companyUpdate',
	'customerPhoenixLicense.modulesIndex': 'customerPhoenixLicense.modulesIndex',
	'customerPhoenixLicense.modulesUpdate': 'customerPhoenixLicense.modulesUpdate',
	'customerPhoenixLicense.subscriptionsIndex': 'customerPhoenixLicense.subscriptionsIndex',
	'customerPhoenixLicense.subscriptionsShow': 'customerPhoenixLicense.subscriptionsShow',
	'customerPhoenixLicense.subscriptionsStore': 'customerPhoenixLicense.subscriptionsStore',

	'tse.history': 'tse.history',
	'tse.download.request_create': 'tse.download.request_create',
	'tse.download.request_get': 'tse.download.request_get',
	'tse.download': 'tse.download',

	'api.list': 'api.list',
	'api.generate': 'api.generate',

	'posbill-backup.api-key.list': 'posbill-backup.api-key.list',
	'posbill-backup.api-key.generate': 'posbill-backup.api-key.generate',
	'posbill-backup.list-backups': 'posbill-backup.list-backups',
	'posbill-backup.request-backup-download': 'posbill-backup.request-backup-download',
	'posbill-backup.download-request-list': 'posbill-backup.download-request-list',
	'posbill-backup.download-backup': 'posbill-backup.download-backup',
}
export default localizationConstants;
