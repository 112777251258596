import { lazy } from 'react';
import { withRouter } from 'react-router';

const Dashboard = withRouter(lazy(() => import('features/dashboard/component/dashboard')));

// const MachineList = withRouter(lazy(() => import('features/machineList/container/machineList')));
// const MachineDetail = withRouter(lazy(() => import('features/machineList/container/machineDetail')));

// const CompanyList = withRouter(lazy(() => import('features/company/container/company')));
// const CompanyDetail = withRouter(lazy(() => import('features/company/container/companyDetail')));

const TerracloudBackupList = withRouter(lazy(() => import('features/tseBackup/terracloud/container/terracloudBackup')));
const ApiKeyList = withRouter(lazy(() => import('features/tseBackup/apiKey/container/apiKey')));
const PosbillBackupApiKeyList = lazy(() => import('features/posbillBackup/apiKeyList/container/apiKey'));
const PosbillBackupBackups = lazy(() => import('features/posbillBackup/backups/container/backups'));
const PosbillBackupDownloadRequests = lazy(() => import('features/posbillBackup/downloadRequests/container/downloadRequests'));

export {
	Dashboard,
	// MachineList, MachineDetail,
	// CompanyList, CompanyDetail,
	TerracloudBackupList,
	ApiKeyList,
	PosbillBackupApiKeyList,
	PosbillBackupBackups,
	PosbillBackupDownloadRequests,
}
