import * as actionTypes from 'store/action-types';
import { ApiKeyState, Action } from 'shared/interface';
import { PER_PAGE } from 'shared/constants/constants';

const initialState: ApiKeyState = {
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0
	},
	columns: {},
	sort: {
		orderBy: 'desc',
		orderByColumn: ''
	},
	filters: {}
}

const reducer = (state: ApiKeyState = initialState, action: Action): ApiKeyState => {
	switch (action.type) {
		case actionTypes.FETCH_APIKEY_LIST_INIT:
			return {
				...state,
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				},
				pagination: {
					...state.pagination,
					per_page: action.payload.perPage,
					current_page: action.payload.page,
				}
			}
		case actionTypes.FETCH_APIKEY_LIST_SUCCESS:
			return {
				...state,
				pagination: action.payload.pagination,
				columns: action.payload.columns,
			};
		default:
			return state;
	}
}

export default reducer;
