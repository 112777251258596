import moment from 'moment';
import { Action, State } from '../interface';

export const createAction = (ACTION: string, data: any = null): Action => {
	return {
		type: ACTION,
		payload: data
	};
};

export const createLoadingSelector = (actions: string[]) => (state: State) => {
	// returns true only when all actions is not loading
	let loader = false;
	for (let i = 0; i < actions.length; i += 1) {
		if (state.loading.api[actions[i]]) {
			loader = true;
			break;
		}
	}
	return loader;
};

/**
 * function which returns formatted date
 * @param date
 */
export const formatDate = (date: any, format?: string) => {
	if (!date) {
		return '';
	}
	return moment(date).local().format(format || 'YYYY-MM-DD HH:mm:ss');
}

export const formatBytes = (bytes: any, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const debounce = (func: any, wait = 500) => {
	let h: NodeJS.Timeout;
	return (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
}

export function moveArrayItemToNewIndex(arr: any, old_index: number, new_index: number) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; 
};
